import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 5×2\\@85%1RM`}</p>
    <p>{`Glute Ham Raise 5×4 weighted if possible`}</p>
    <p>{`then,`}</p>
    <p>{`For Time:`}</p>
    <p>{`100 Calorie Row`}</p>
    <p>{`100 Situps`}</p>
    <p>{`50 Burpees`}</p>
    <p>{`50 Back Squats (135/95)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      